import ReactLoading from 'react-loading'

const Loading = ({ size }) => (
	<div className="flex w-full bg-slate-50" style={{ height: '90vh', height: '100svh' }}>
		<ReactLoading
			type={'bubbles'}
			className="m-auto"
			height={size ?? 100}
			width={size ?? 100}
			color={'#868e96'}
		/>
	</div>
)

export default Loading
//	className="flex justify-content-center align-items-center mx-auto w-full bg-red-100"
