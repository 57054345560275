// NOTE: hooks
import React, { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
// NOTE: components
import Loading from './components/shared/Loading'
// NOTE: pages
const Home = lazy(() => import('./pages/Home'))
const Error = lazy(() => import('./pages/404.js'))

const LoadPage = ({ page }) => {
	return <Suspense fallback={<Loading />}>{page}</Suspense>
}

const App = () => {
	const routing = useRoutes([
		{
			index: true,
			element: <LoadPage page={<Home />} />
		},
		{
			path: '*',
			element: <LoadPage page={<Error />} />
		}
	])

	return routing
}

export default App
